<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" lg="6" md="6" sm="12" class="left-data">
                <img :src="generateImageUrl()" alt=""><br><br>
                    <v-row>
                        <v-col cols="12" lg="6" md="4" sm="12">
                            <div class="social-icons">
                                <img class="social-icon" src="@/assets/social_icons/facebook.svg" alt="" @click="goTo(brand.facebook)">
                                <img class="social-icon" src="@/assets/social_icons/instagram.svg" alt="" @click="goTo(brand.instagram)">
                                <img class="social-icon" src="@/assets/social_icons/youtube.svg" alt="" @click="goTo(brand.youtube)">
                            </div>
                            <br>
                            <p>&copy; 2024 Desenvolvido por Divulga Digital</p>
                        </v-col>
                    </v-row>
            </v-col>
            <v-col cols="1" lg="3" md="1" sm="1"></v-col>
            <v-col>
                <div class="row">
                    <v-col>
                        <h3>{{ brand.nome }}</h3>
                    </v-col>
                </div>
                <v-container style="font-size: 13px;">
                    <v-row>
                        <v-col cols="1"><img src="@/assets/icons/location.svg" alt=""></v-col>
                        <v-col>{{ brand.logradouro }}<br> {{ brand.bairro }}, {{ brand.municipio }} - {{ brand.estado }}</v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="1" v-if="brand.fone"><img src="@/assets/icons/phone.svg" alt=""></v-col>
                        <v-col v-if="brand.fone">({{ brand.ddd }}) {{ brand.fone.substr(0, 5) }}-{{ brand.fone.substr(-4) }}</v-col>
                        <v-col cols="1" v-if="brand.whatsapp"><img src="@/assets/icons/whatsapp.svg" alt=""></v-col>
                        <v-col v-if="brand.whatsapp">
                            <a :href="`https://wa.me/55${brand.whatsapp}`" target="blank">({{ brand.whatsapp.substr(0, 2) }}) {{ brand.whatsapp.substr(2, 5) }}-{{ brand.whatsapp.substr(-4) }}</a>    
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="1"><img src="@/assets/icons/email.svg" alt=""></v-col>
                        <v-col>
                            <a :href="`mailto:${brand.email}`" target="blank">{{ brand.email }}</a>   
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
/* eslint-disable */
import generateImageUrl from '@/utils/generateImageUrl';
import goTo from 'vuetify/lib/services/goto';
export default {
    name: 'FooterComponent',
    props: {
        brand: {
            type: Object,
            required: true
        }
    },
    methods: {
        generateImageUrl(){
            return generateImageUrl(this.brand.logoRodape, this.$store.state.userData)
        },
        goTo(url){
            window.open(url, '_blank')
        }
    }
}
</script>

<style>
.left-data img{
    width: 45%;
    margin: 0 auto;
}

@media (max-width: 600px){
    .left-data img{
        width: 100%;
    }
}

.social-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: -10px;
}

.social-icons .social-icon{
    width: 20px !important;
    margin: 0 10px;
    cursor: pointer;
}

a{
    color:#000;
}
</style>