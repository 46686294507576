<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      title: {}
    }
  },
  created(){
    let dados = this.$store.state.userData
    this.title = dados.full_name

    if(!localStorage.cart){
      localStorage.setItem('cart', JSON.stringify([]))
    }

  },
  metaInfo() {
    return {
      title: this.title
    }
  },
  methods:{
  }
}
</script>