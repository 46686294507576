<template>
    <div class="fill">
        <div class="loading">
            <img src="../assets/loading.gif" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingComponent'
}
</script>

<style>
.fill {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>