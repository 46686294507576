function generateImageUrlProduct(image, userData) {
  if(image.nome.indexOf('charmosatravessia') != -1){
    return image;
  }else{
    if(image.nome.indexOf('storeimg') != -1){
      return image;
    }
    image.nome = `https://storeimg.smartpdvstore.com/${userData.client_code}/${userData.brand_code}/${image.nome.split('.').join('@1000_750.')}`
    return image;
  }
}

export default generateImageUrlProduct;