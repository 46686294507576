<style>
  .title{
    color: #333;
    font-size: 54px;
    font-weight: bold;
    text-align: center;
    font-weight: 400;
  }

  .subtitle{
    color: #333;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    font-weight: 400;
  }
</style>

<template>
  <v-container>
    <v-row v-if="LoadingPage">
      <v-col>
        <Loading ></Loading>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <h1 class="title">{{ catalog.nome }}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img :src="generateImageUrl(catalog.banner)" width="100%"></v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h2 class="subtitle">{{ catalog.nome }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img :src="generateImageUrl(catalog.grupos[0].banner)" width="100%"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="12" style="padding: 0;margin: 0;" v-for="p in catalog.grupos[0].produtos" :key="p.code">
              <cardProduct :product="p" @updateCart="updateCart" ></cardProduct>
            </v-col>
          </v-row>
          <Footer :brand="brand"></Footer>
          <whatsappFloat :brand="brand"></whatsappFloat>
          <Cart :products="cartProducts" @incrementProduct="incrementProduct" @decrementProduct="decrementProduct" @deleteProduct="deleteProduct" v-if="openCart"></Cart>
          <ToggleCart @toggleCart="toggleCart" :cartTotal="cartTotal" :status="openCart"></ToggleCart>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
/* eslint-disable */
import { getCatalog, getBrand } from '@/commands/smartpdvCommands.js';
import generateImageUrl from '@/utils/generateImageUrl';

//COMPONENTS
import cardProduct from '@/components/cardProduct.vue';
import Loading from '@/components/loading.vue';
import Footer from '@/components/Footer.vue';
import whatsappFloat from '@/components/whatsappFloat.vue';
import Cart from '@/components/Cart.vue';
import ToggleCart from '@/components/toggleCart.vue';

import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
  name: 'HomeView',
  data () {
    return {
      userData: {},
      catalog: [],
      brand: {},
      LoadingPage: false,
      cartProducts: [],
      openCart: false,
      cartTotal: 0
    }
  },
  components:{
    cardProduct,
    Loading,
    Footer,
    whatsappFloat,
    Cart,
    ToggleCart
  },
  async created(){
    this.LoadingPage = true
    await this.getStoreData()
    await this.init()
    await this.updateCart()
    this.LoadingPage = false
  },
  methods:{
    async getStoreData(){
      let catalogCode = this.$route.params.catalogCode
      await this.$store.dispatch('getStore', catalogCode)
    },
    async init(){
      this.userData = !localStorage.getItem('userData') ? await this.$store.state.userData : JSON.parse(localStorage.getItem('userData'))
      await this.getCatalog()
      await this.getBrand()
    },
    generateImageUrl(image){
      return generateImageUrl(image, this.userData)
    },
    async getCatalog(){
      console.log(this.userData)
      const response = await getCatalog(this.userData.brand_code, this.userData.catalogue_code, this.userData.brand_url)
      this.catalog = response.catalogo
    },
    async getBrand(){
      const response = await getBrand(this.userData.brand_url)
      this.brand = response.marca
    },
    updateCart(){
      this.cartProducts = JSON.parse(localStorage.getItem('cart'))
      this.$store.commit('updateCartTotal', this.cartProducts.length)
      this.cartTotal = this.$store.state.cartTotal ? this.$store.state.cartTotal : JSON.parse(localStorage.getItem('cart')).length
    },
    incrementProduct(product){
      console.log(product)
      let cart = JSON.parse(localStorage.getItem('cart'))
      let index = cart.findIndex(p => p.codigo == product.codigo && p.cor == product.cor && p.tamanho == product.tamanho) 
      if(index >= 0){
        cart[index].estoquePedido++
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.updateCart()
    },
    decrementProduct(product){
      let cart = JSON.parse(localStorage.getItem('cart'))
      let index = cart.findIndex(p => p.codigo == product.codigo && p.cor == product.cor && p.tamanho == product.tamanho) 
      if(index >= 0){
        cart[index].estoquePedido--
        if(cart[index].estoquePedido <= 0){
          cart.splice(index, 1)
        }
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.updateCart()
    },
    deleteProduct(product){
      let cart = JSON.parse(localStorage.getItem('cart'))
      let index = cart.findIndex(p => p.codigo == product.codigo && p.cor == product.cor && p.tamanho == product.tamanho) 
      if(index >= 0){
        cart.splice(index, 1)
      }
      localStorage.setItem('cart', JSON.stringify(cart))
      this.updateCart()
    },
    toggleCart(status){
      this.openCart = status
    }
  }
}
</script>
