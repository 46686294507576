<template>
    <div class="btn-cart-float-right-top">
        <v-badge
            bordered
            color="#ff4e54"
            :content="cartTotal"
            overlap
        >
        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="#ff4e54"
            @click="toggleCart"
        >
        <v-icon v-if="status" dark>mdi-close</v-icon>
        <v-icon v-if="!status" dark>mdi-cart</v-icon>
        </v-btn>
        </v-badge>
    </div>
    
    
</template>

<script>
export default {
    name: "toggleCart",
    props: {
        status: {
            type: Boolean,
            default: false
        },
        cartTotal: {
            type: Number,
            default: 0
        }
    },
    methods: {
        toggleCart() {
            if(this.status){
                this.$emit('toggleCart', false)
            }else{
                this.$emit('toggleCart', true)
            }
        }
    }
}
</script>

<style scoped>
.btn-cart-float-right-top {
    position: fixed;
    right: 10px;
    top: 20px;
    z-index: 999;
}
</style>